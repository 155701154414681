import React from "react"
import Img from "gatsby-image"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DownloadButton from "../components/download"

const Page = ({ data }) => {
  return (
    <Layout containerId="pricing">
      <Seo title="Pris" />

      <div className="feature-sections">
        <section className="full-width">
          <div className="grid full-width-grid">
            <div className="full-width-grid" style={{ textAlign: "center" }}>
              <h2 className="feature-group-title">Priser</h2>
              <p className="feature-group-decription">
                Start gratis og uforpliktende. Oppgrader dersom du trenger full
                frihet.
              </p>
            </div>
          </div>
        </section>
        <table>
          <thead>
            <tr>
              <th />
              <th>Gratis</th>
              <th>
                Premium{" "}
                <span role="img" aria-hidden={true}>
                  ✨
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pris</td>
              <td>0 kr</td>
              <td>
                44 kr/mnd
                <br />
                399 kr/år
              </td>
            </tr>
            <Row data={data} title="Lister" free="Inntil 5" />
            <Row
              data={data}
              title="Rutiner"
              free="Inntil 5 steg totalt"
              link="/features/routines"
            />
            <Row
              data={data}
              title="Refleksjon"
              free="1 spørsmål pr. dag"
              link="/features/reflection"
            />
            <Row
              data={data}
              title="Historikk på fullførte oppgaver"
              free="7 dager"
            />
            <Row
              data={data}
              title="Quick-add"
              free={true}
              link="/features/capture"
            />
            <Row
              data={data}
              title="Smart sortering"
              free={true}
              link="/features/organize"
            />
            <Row
              data={data}
              title="Prioriteringshjelp"
              free={true}
              link="/features/countup"
            />
            <Row data={data} title="Delte lister" free={true} />
            <Row data={data} title="iOS + Mac apps" free={true} />
            <Row data={data} title="Offline-støtte" free={true} />

            <Row data={data} title="Påminnelser" />
            <Row data={data} title="Prioritert support" />
          </tbody>
        </table>

        <div className="cta-section">
          <h2 style={{ maxWidth: "450px", margin: "1em auto 0 auto" }}>
            Prøv gratis
          </h2>
          <p>
            Vi har foreløpig apper for iPhone, iPad og Mac.
            <br />I fremtiden håper vi å støtte enda flere plattformer!
          </p>

          <DownloadButton className="download-btn-footer" />
          <NonStretchedImage
            fluid={data.partialPhone.childImageSharp.fluid}
            alt={`Telefon med Mente`}
          />
        </div>
      </div>
    </Layout>
  )
}

const NonStretchedImage = (props) => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

const Row = ({ data, title, free, link }) => {
  let freeText = null
  if (typeof free !== "undefined") {
    if (typeof free === "string") {
      freeText = free
    } else {
      freeText = free ? (
        <Img fixed={data.iconCheckmark.childImageSharp.fixed} alt="Ja" />
      ) : null
    }
  }
  return (
    <tr>
      <td>{link ? <Link to={link}>{title}</Link> : title}</td>
      <td className="feature-available">{freeText}</td>
      <td className="feature-available">
        <Img fixed={data.iconCheckmark.childImageSharp.fixed} alt="Ja" />
      </td>
    </tr>
  )
}

const WrappedPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        iconCheckmark: file(relativePath: { eq: "icon-checkmark.png" }) {
          childImageSharp {
            fixed(width: 23) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        downloadAppstore: file(relativePath: { eq: "download-appstore.png" }) {
          childImageSharp {
            fixed(width: 188) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        partialPhone: file(relativePath: { eq: "partial-phone.png" }) {
          childImageSharp {
            fluid(maxWidth: 397) {
              ...GatsbyImageSharpFluid_noBase64
              presentationWidth
            }
          }
        }
      }
    `}
    render={(data) => <Page data={data} {...props} />}
  />
)

export default WrappedPage;
